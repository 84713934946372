function scrollToElement() {
    $('.scroll-btn').on('click', function(e) {
        e.preventDefault();
        var location = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(location).offset().top
        }, 1000);
    });
}

$(document).ready(function() {
    scrollToElement();
});